import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/twitter-multiple-accounts.jpg';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="複数アカウントフォローキャンペーン"
		  desc="このサンプルは1回のキャンペーンでTwitterの複数アカウントのフォローを実施します。グループやコラボレーション、企業タイアップに適した方法です。属性の近いアカウントとコラボレーションすると質の高いリード獲得ができます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/twitter/multi-account'}
		  type={'Article'}
		  shortName="Twitter複数アカウントフォロー"
		  createdAt="2021-11-26"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="Twitter複数アカウントフォロー"
		homePageText="Home"
		homePageUrl="/"
		activePageText="Twitter複数アカウントフォロー"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/twitter',
		  name: 'Twitterキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"Twitterの複数アカウントのフォロー"}
		description={<>このサンプルは1回のキャンペーンでTwitterの複数アカウントのフォローを実施します。グループやコラボレーション、企業タイアップに適した方法です。<br/>属性の近いアカウントとコラボレーションすると質の高いリード獲得ができます。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS, Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS, Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS
		  , Constants.CAMPAIGN_EFFECTS.GET_HIGH_SPREAD, Constants.CAMPAIGN_EFFECTS.GET_CUSTOMER_LEAD
		  , Constants.CAMPAIGN_EFFECTS.VERIFIED_BADGE, Constants.CAMPAIGN_EFFECTS.RETENTION]}
		plan={'ベーシックプラン以上'}
		promotionId="c22040c4a0dcf4b491"
		platformExample={
		  <PlatformExample
			title="その他のTwitterキャンペーン"
			types={['twitterFollowRetweet', 'twitterFollowLike', 'twitterFollowHashtag','twitterToLine','twitterInstantWin','twitterMultipleAccounts']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples